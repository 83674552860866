import { Box, styled } from "@mui/material"
import TopMacroNavigation from "components/navigation/TopMacroNavigation"
import React from "react"
import { pxToRem } from "utils/helpers"

interface ContainerProps {
  children: React.ReactNode
  className?: string
}

const Macro = (props: ContainerProps) => (
  <_Container>
    <TopMacroNavigation />
    <_Content className={props.className} component="main" id="main-content">
      {props.children}
    </_Content>
  </_Container>
)

export default Macro

const _Container = styled(Box)(({}) => ({
  height: "100%",
  width: "100%",
  overflowX: "hidden",
})) as typeof Box

const _Content = styled(Box)(({ theme }) => ({
  padding: pxToRem(20),
  margin: `0 auto`,
  maxWidth: pxToRem(1440),
  [theme.breakpoints.up("md")]: {
    padding: pxToRem(40),
  },
})) as typeof Box
